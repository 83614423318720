
/**
 * @name: basic
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：销售管理-网红视频管理-添加，修改  基础信息
 * @update: 2023-06-01 11:21
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Message} from "element-ui";
import config from "@/config";
import {ICourse} from "@/apis/coursesManage/courses/types";

@Component({})
export default class ProductBasic extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: ICourse;
  // 新增修改表单rules
  @Prop(Object) rules!: any;


  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * IUpload
   * 文件提交，成功或失败
   * @param res
   */
  onFileUpload(res: any) {
    if (res) {
      console.log(res, 'onFileUpload')
    }

  }

  /**
   * 上传视频前检验
   * @param file
   */
  beforeVideoUpload(file: File) {
    let fileSize = file.size / 1024 / 1024 < 100;   //控制大小  修改50的值即可
    if (
      [
        "video/mp4",
        "video/ogg",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ].indexOf(file.type) == -1     //控制格式
    ) {
      Message.error("请上传正确的视频格式");
      return false;
    }
    if (!fileSize) {
      Message.error("视频大小不能超过100MB");
      return false;
    }
  }

  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm,tab)
  }

}
